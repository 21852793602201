import styled from 'styled-components';

export const Container = styled.div`
  ${({ layerId, editing }) => {
    if (editing) {
      return `
        border: 1px dashed red;

        &:before {
            content: "${layerId}";
            position:absolute;
            top: -24px;
            left: 0;
            background: red;
            color: yellow;
            font-size: 12px;
            padding: 5px 10px 3px;
            font-weight: bold;
            border-radius: 10px 10px 0 0;
        }
        `;
    }
  }}
`;

export const NoChildren = styled.div`
  padding: 50px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
`;
